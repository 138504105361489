'use strict'

angular
  .module 'mundoReporting'
  .directive 'svgImage', [
    '$http'
    '$interpolate'
    ($http, $interpolate) ->
        restrict: 'E'
        priority: 50
        link: (scope, element) ->
          imgUrl = element.attr 'svg-source'
          imgUrl = $interpolate(imgUrl)(scope)

          request = $http.get imgUrl,
            cache: true
            headers:
              'Content-Type': 'application/xml'

          manipulateSvgImage = (data, elem) ->
            $svg = angular.element(data)[2]

            fillColor = elem.attr 'svg-fill-color'
            if fillColor?
              fillColor = $interpolate(fillColor)(scope)

              for x in $svg.querySelectorAll('path')
                x.setAttribute 'style', "#{x.getAttribute('style')};fill:#{fillColor};"

            for x in ['class', 'style', 'id']
              attr = elem.attr x

              if attr?
                $svg.setAttribute x, attr

            $svg.removeAttribute 'xmlns:a'

            return $svg

          request.then (response) ->
            element.replaceWith manipulateSvgImage(response.data, element)
  ]
